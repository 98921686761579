.footer {
    width: 100%;
    height: 320px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    color: #ffffff;
    background: linear-gradient(180deg, #00756d 0%, #004e55 100%);
  }
  .footerImg {
    margin-bottom: 30px;
  }