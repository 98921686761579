@import url("https://fonts.googleapis.com/css2?family=Archivo&family=Inter:wght@100&display=swap");
* {
  margin: 0;
  box-sizing: border-box;
  font-family: "Archivo", sans-serif;
}
html {
  height: 100%;
  width: 100%;
}
body {
  height: 100%;
  width: 100%;
}
.app {
  height: 100%;
  width: 100%;
}
.body {
  width: 100%;
  background-color: #8cc6ab;
}
.firstContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 100vh;
  padding: 50px 45px;
  background-repeat: no-repeat;
  margin-top: 60px;
  background-size: cover;
  background-image: url("../assets/background-mobile.png");
}
.firstContainerTextWrap {
  display: flex;
  flex-direction: column;
}
.firstContainerTitle {
  text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  height: 65px;
  width: 100%;
  font-size: 32px;
  font-weight: 700;
  color: #bed7b0;
  margin-bottom: 30px;
}
.firstContainerText {
  text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  height: 65px;
  width: 100%;
  font-size: 18px;
  font-weight: 400;
  color: #bed7b0;
  margin-bottom: 50px;
}
.form {
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: rgba(255, 255, 255, 0.9);
  border-radius: 20px;
  width: 100%;
  height: 385px;
  padding: 20px 25px;
  margin-left: 10px;
}
.formTitle {
  width: 80%;
  color: #001a05;
  font-size: 24px;
  font-weight: 700;
  text-align: center;
  margin-bottom: 15px;
}
label {
  width: 100%;
  font-size: 16px;
  font-weight: 400;
  color: #004e55;
}
input {
  width: 100%;
  font-size: 16px;
  font-weight: 300;
  color: #004e55;
  border-radius: 10px;
  background: rgba(255, 255, 255, 0.95);
  border: 0.5px solid rgba(79, 66, 93, 0.2);
  height: 35px;
  margin-bottom: 5px;
}
.formButton {
  width: 100%;
  height: 40px;
  background-color: #ff333e;
  border: 0.5px solid rgba(79, 66, 93, 0.2);
  border-radius: 10px;
  font-weight: 700;
  font-size: 20px;
  color: #ffffff;
  cursor: pointer;
}
.secondContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: #8cc6ab;
  padding: 45px;
}
.secondContainerTitle {
  text-align: center;
  width: 280px;
  font-size: 20px;
  font-weight: 700;
  margin-bottom: 45px;
}
.secondContainerItems {
  background-color: rgba(0, 117, 109, 1);
  border-radius: 25px;
  width: 100%;
  height: fit-content;
  margin-bottom: 90px;
  color: white;
  padding: 25px;
}
.itemsContainer {
  margin-bottom: 20px;
  display: flex;
}
.checkbox {
  margin-right: 20px;
}
.thirdContainer {
  width: 100%;
  /* height: 100vh; */
  background-color: #8cc6ab;
}
.thirdContainerImg {
  width: fit-content;
  max-height: 255px;
  object-fit: cover;
  margin-bottom: 50px;
}
.thirdContainerItems {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 0px 45px;
}
.thirdContainerTitle {
  width: 300px;
  font-size: 20px;
  font-weight: 700;
  text-align: center;
  margin-bottom: 20px;
}
.thirdContainerText {
  font-size: 16px;
  font-weight: 400;
  margin-top: 20px;
}
.fourthContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: #eaeaea;
  padding: 15px 35px;
}
.fourthContainerTitle {
  font-size: 24px;
  font-weight: 700;
  color: rgba(0, 78, 85, 1);
  margin: 85px 0px 50px 0px;
}
.fourthContainerGrid {
  display: grid;
  grid-gap: 10px;
  grid-template-columns: repeat(1, 1fr);
}
.fourthContainerItems {
  width: 100%;
  height: 110px;
  background-color: rgba(0, 78, 85, 1);
  border-radius: 10px;
  padding: 10px 25px;
  display: flex;
  flex-direction: column;
  justify-content: center;
}
.itemsCardTitle {
  width: 100%;
  font-size: 18px;
  font-weight: 700;
  color: rgba(190, 215, 176, 1);
  text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  margin-bottom: 20px;
  text-transform: uppercase;
}
.itemsCardText {
  color: #ffffff;
  font-size: 14px;
  font-weight: 400;
  text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
}
.fifthContainer {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 315px;
  padding: 50px;
  background: linear-gradient(180deg, #00756d 0%, #004e55 100%);
}
.fifthContainerTitle {
  font-size: 20px;
  font-weight: 700;
  color: #ffffff;
  margin: 40px 0px;
}
.fifthContainerItems {
  display: flex;
  width: 100%;
  justify-content: space-around;
}
.fifthContainerNames {
  font-size: 18px;
  font-weight: 600;
  color: #f4f2ff;
}
.avatarContainer {
  display: flex;
  align-items: center;
  flex-direction: column;
  margin-bottom: 60px;
}
.sixthContainer {
  width: 100%;
  background-color: #8cc6ab;
  padding: 0px 40px;
  display: flex;
  align-items: center;
  flex-direction: column;
}
.sixthContainerTitle {
  font-size: 20px;
  font-weight: 800;
  width: 280px;
  text-align: center;
  margin: 70px 0px 55px 0px;
}
.sixthContainerCard {
  width: 100%;
  height: 265px;
  background-color: #eaeaea;
  border-radius: 25px;
  border: 3px solid #004e55;
  margin-bottom: 15px;
  display: flex;
  flex-direction: column;
}
.alumnosCardTitle {
  display: flex;
  padding: 15px;
}
.alumnosName {
  display: flex;
  flex-direction: column;
  padding: 5px 15px;
}
.sixthContainerCardName {
  font-weight: 700;
}
.alumnosImg {
  width: 50px;
  height: 50px;
}
.starImg {
  height: 15px;
  width: 15px;
}
.sixthContainerCardText {
  color: #004e55;
  font-weight: 400;
  padding: 0px 15px;
}
.ofertasCard {
  width: 100%;
  max-width: 985px;
  height: 215px;
  background-color: #e5f1f0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border-radius: 25px;
  padding: 35px 35px 20px 35px;
  margin-top: 80px;
  margin-bottom: 65px;
}
.ofertasButton {
  width: 100%;
  height: 55px;
  background-color: #ff333e;
  border: 0.5px solid rgba(79, 66, 93, 0.2);
  border-radius: 25px;
  font-weight: 800;
  font-size: 20px;
  color: #f4f2ff;
  cursor: pointer;
}
.ofertasTitle {
  color: #004e55;
  font-weight: 400;
  font-size: 24px;
  margin-bottom: 25px;
}
input::placeholder{
  padding-left: 10px;
}

@media (max-width: 430px) {
  .formButton {
    font-size: 14px;
  }
  .ofertasButton {
    font-size: 14px;
  }
  .fifthContainerNames {
    font-size: 14px;
  }
  .ofertasTitle {
    font-size: 16px;
  }
  .sixthContainer{
    padding: 0px 20px;
  }
  .thirdContainerImg{
    width: 100%;
  }
}

@media (min-width: 435px) {
  .firstContainer {
    background-image: url("../assets/background.png");
  }
  .form {
    max-width: 400px;
  }
  .firstContainerTextWrap {
    width: 380px;
  }
  .thirdContainer {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 50px;
  }
  .thirdContainerImg {
    border-radius: 25px;
  }
}

@media (min-width: 650px) {
  .secondContainerItems {
    display: grid;
    max-width: 1120px;
    grid-template-columns: repeat(2, 1fr);
  }
  .secondContainerTitle {
    width: 100%;
  }
  .thirdContainerTitle {
    width: 100%;
  }
  .fourthContainerGrid {
    grid-template-columns: repeat(2, 1fr);
    grid-gap: 15px;
  }
  .fifthContainerItems {
    width: 540px;
  }
  .sixthContainerTitle {
    width: 100%;
  }
  .sixthContainerGrid {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-gap: 10px;
  }
  .sixthContainerCard {
    max-width: 350px;
  }
  .ofertasTitle {
    font-size: 32px;
  }
  .ofertasButton {
    max-width: 420px;
    font-size: 24px;
  }
}

@media (min-width: 1024px) {
  .firstContainer {
    display: flex;
    align-items: center;
    flex-direction: row;
    justify-content: space-between;
    padding: 80px;
  }
  .firstContainerTextWrap {
    width: 500px;
  }
  .firstContainerTitle {
    font-size: 48px;
    margin-bottom: 50px;
  }
  .firstContainerText {
    font-size: 22px;
  }
  .thirdContainer {
    display: flex;
    flex-direction: row-reverse;
    padding: 50px;
  }
  .fourthContainerGrid {
    grid-template-columns: repeat(3, 1fr);
  }
  .fifthContainerTitle {
    font-size: 28px;
  }
  .sixthContainerTitle {
    font-size: 28px;
  }
  .sixthContainerCard {
    margin-right: 40px;
  }
}
