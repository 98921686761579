/* ---------------- Navbar and burger menu ------------------ */

.menu-active {
  display: flex;
  color: #ffffff;
  font-weight: 600;
}

.menu-sections {
  margin-right: 10px;
  cursor: pointer;
}

@media (min-width: 1024px) {
  .menu-visible {
    font-size: 18px;
  }
  .menu-sections {
    margin-right: 25px;
  }
}

@media (max-width: 650px) {
  nav {
    padding: 20px;
  }

  .burger-menu {
    height: 30px;
    width: 30px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: space-evenly;
    cursor: pointer;
  }

  .burger-bar {
    width: 30px;
    height: 2px;
    background-color: #ffffff;
    border-radius: 2px;
  }

  .menu {
    width: 100%;
    height: 100vh;
    background-color: #004b51;
    position: absolute;
    top: 0;
    right: 0;
    z-index: -1;
  }

  .hidden {
    width: 0;
    transition: ease-in-out 0.5s;
  }

  .visible {
    display: inherit;
    transition: ease-in-out 0.5s;
  }

  .menu-active {
    display: flex;
    flex-direction: column;
    width: 100%;
    justify-content: center;
    align-items: center;
    padding: 10px;
    height: 120px;
    margin-top: 120px;
    font-weight: 600;
    font-size: 18px;
    color: #ffffff;
  }

  .menu-sections {
    width: 100%;
    padding: 20px 0px;
    text-align: center;
    letter-spacing: 0.2rem;
    border-bottom: solid 1px darkgray;
  }

  .burger-bar.clicked:nth-child(1) {
    transform: rotate(45deg) translate(0px, 4px);
    transition: ease-out 0.5s;
  }

  .burger-bar.clicked:nth-child(2) {
    transform: scale(0);
    transition: ease-out 0.5s;
  }

  .burger-bar.clicked:nth-child(3) {
    transform: rotate(135deg) translate(-7px, 12px);
    transition: ease-out 0.5s;
  }

  .burger-bar.unclicked {
    transform: rotate(0) translate(0);
    transition: cubic-bezier(0.175, 0.885, 0.32, 1.275) 0.5s;
  }
}
