.alertaContainer {
  position: fixed;
  width: 50%;
  height: 455px;
  top: 50%;
  left: 50%;
  display: flex;
  justify-content: center;
  z-index: 100;
  background-color: #004b51;
  border-radius: 25px;
  transform: translate(-50%, -50%);
  box-shadow: 3px 3px 14px 0px rgba(0, 0, 0, 0.75);
}
.alertaContent {
  width: 100%;
  max-width: 400px;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  align-items: center;
  color: #ffffff;
  margin-bottom: 65px;
  padding: 5px;
}
.modalImg {
  width: 112px;
  height: 143px;
}
.modalTitle {
  font-size: 28px;
  font-weight: 700;
}
.modalText {
  font-size: 20px;
  font-weight: 400;
  width: 100%;
}
.modalButton {
  position: absolute;
  width: 150px;
  height: 45px;
  right: 4%;
  bottom: 4%;
  background-color: #bed7b0;
  border-radius: 10px;
  font-size: 20px;
  font-weight: 700;
  color: #001a05;
  border: 0.5px solid rgba(79, 66, 93, 0.2);
  cursor: pointer;
}
.modalCross {
  position: absolute;
  top: 4%;
  right: 4%;
  font-size: 20px;
  font-weight: 700;
  color: #ffffff;
  cursor: pointer;
}

@media (max-width: 650px) {
  .alertaContainer {
    height: 350px;
  }
  .modalTitle {
    font-size: 22px;
  }
  .modalText {
    font-size: 16px;
  }
  .modalImg {
    width: 80px;
    height: 100px;
  }
  .modalButton {
    font-size: 16px;
    width: 100px;
    height: 25px;
  }
}
