.header {
  height: 60px;
  width: 100%;
  background-color: #004b51;
  display: flex;
  justify-content: space-around;
  align-items: center;
  margin: 0;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 9999;
}

.logoImg {
  height: 40px;
  margin-left: 30px;
  cursor: pointer;
}

.tycNavbar {
  font-size: 18px;
  font-weight: 600;
  color: #ffffff;
}

@media (max-width: 650px) {
  .header {
    justify-content: space-between;
  }
  .tycNavbar {
    font-size: 12px;
    margin-right: 20px;
  }
}
