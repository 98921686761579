.tycBody {
  width: 100%;
  height: 100%;
  margin-top: 60px;
  background-color: #8cc6ab;
  display: flex;
  align-items: center;
  flex-direction: column;
  padding: 0px 45px 90px 45px;
}
.tycTitle {
  font-size: 20px;
  font-weight: 700;
  color: #000000;
  text-align: center;
  width: 100%;
  margin: 30px 0px;
}
.tycCard {
  width: 100%;
  background-color: #00756d;
  border-radius: 25px;
  display: flex;
  flex-direction: column;
  padding: 25px 25px 15px 25px;
  color: #ffffff;
}
.tycCardTitle {
  font-weight: 700;
  font-size: 18px;
  margin-bottom: 20px;
}
.tycText{
  font-weight: 400;
  font-size: 14px;
  margin-bottom: 15px;
}
